<sm-dialog-template [iconClass]="modeParameters[mode]?.icon" [header]="modeParameters[mode]?.header">
  @if ( mode === 'create') {
    <sm-create-new-project-form
      [projects]="projects$ | ngrxPush"
      [baseProject]="baseProject"
      (filterSearchChanged)="filterSearchChanged($event)"
      (projectCreated)="createProject($event)">
    </sm-create-new-project-form>
  }
  @if ( mode === 'edit') {
    <sm-edit-project-form
      [project]="baseProject"
      (projectUpdated)="updateProject($event)"
      (cancelClicked)="closeDialog()">
    </sm-edit-project-form>
  }
  @if ( mode === 'move') {
    <sm-project-move-to-form
      [projects]="projects$ | ngrxPush"
      [baseProject]="baseProject"
      (moveProject)="moveProject($event)"
      (filterSearchChanged)="filterSearchChanged($event)"
      (dismissDialog)="closeDialog()">
    </sm-project-move-to-form>
  }
</sm-dialog-template>
